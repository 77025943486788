<template>
  <div>
    <b-row class="mb-1">
      <b-col lg="2" md="2">
        <label> Tipo</label>
        <v-select
          id="tipo_receita"
          v-model="confirmado"
          :options="confirmadoOpcoes"
          :reduce="(confirmado) => confirmado.value"
          label="text"
          class="select-size-sm"
          @input="alterouConfirmado"
          :clearable='false'
        >
          <div slot="no-options">
            não encontrado!
          </div>
        </v-select>
      </b-col>

      <b-col lg="5" md="7">
        <b-form-group
          label="Período"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="tipo"
              v-model="periodo"
              :options="periodos"
              text-field="text"
              value-field="value"
              style="height: 32px"
              @change="alterouPeriodo"

            >
            </b-form-select>
            <b-form-datepicker
              id="data_inicio"
              size="sm"
              v-model="data_inicio"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
          }"
              close-button
              weekday-header-format="narrow"
              offset="-25"
              dropdown
              hide-header
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
              @input="atualizarLista"
            />
            <b-form-datepicker
              id="data_final"
              size="sm"
              v-model="data_final"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
          }"
              close-button
              hide-header
              weekday-header-format="narrow"
              offset="-25"
              dropdown
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
              @input="atualizarLista"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col>
        <label
        ><feather-icon icon="SearchIcon"></feather-icon>
          Procurar</label
        >
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filtro" debounce="500" placeholder="Insira um e-mail..." />
          <b-input-group-append>
            <b-button :disabled="!filtro" @click="filtro = ''"> Limpar </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import {
  BIcon,
  BButton,
  BListGroup,
  BListGroupItem,
  VBToggle,
  BFormDatepicker,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormGroup,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import moment from "moment";

export default {
  directives: { Ripple, "b-toggle": VBToggle },
  components: {
    BIcon,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    vSelect,
    VBToggle,
    BFormDatepicker,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    BFormSelect,
  },
  props: ['fornecedorSelecionado'],
  data() {
    return {
      confirmado: '',
      filtro: '',
      periodo: 3,
      data_inicio: this.$getDate.primeiroDiaMes(),
      data_final: this.$getDate.ultimoDiaMes(),
      periodos: [
        { value: 1, text: "Hoje" },
        { value: 2, text: "Esta Semana" },
        { value: 3, text: "Este mês" },
        { value: 0, text: "Mostrar Todos" },
        { value: 4, text: "Outro Período" },
      ],

      confirmadoOpcoes: [
        { value: 1, text: "Confirmados" },
        { value: 0, text: "Não Confirmados" },
        { value: '', text: "Todos" },
      ],
    };
  },

  mounted() {
    
  },
  watch: {
    filtro() {
      this.buscaEmail();
    },
  },

  methods: {

    listarCadastros() {
      this.$store.dispatch("cadastro_teste/listaCadastros");
    },

    atualizarLista() {
      const payload = {
        data_inicio: this.data_inicio,
        data_final: this.data_final,
      };

      this.$store.commit("cadastro_teste/STORE_FILTRO_DATA", payload)
      this.listarCadastros()
    },

    alterouPeriodo() {
      if (this.periodo === 0) {
        this.data_inicio = "";
        this.data_final = "";
      } else if (this.periodo === 1) {
        this.data_inicio = this.$getDate.hoje();
        this.data_final = this.$getDate.hoje();
      } else if (this.periodo === 2) {
        this.data_inicio = moment().day(0).format("YYYY-MM-DD"); // domingo desta semana
        this.data_final = moment().day(6).format("YYYY-MM-DD"); // sábado desta semana
      } else if (this.periodo === 3) {
        this.data_inicio = moment().startOf("month").format("YYYY-MM-DD");
        this.data_final = moment().endOf("month").format("YYYY-MM-DD");
      } else if (this.periodo === 4) {
        this.data_inicio = this.$getDate.hoje();
        this.data_final = this.$getDate.addDias(null, 30);
      } else if (this.periodo === 5) {
        return;
      }

      let payload = {
 
        data_inicio: this.data_inicio,
        data_final: this.data_final,
   
      };

      this.$store.commit("cadastro_teste/STORE_FILTRO_DATA", payload)
      this.listarCadastros();
    },
    alterouConfirmado() {
      this.$store.commit('cadastro_teste/STORE_TIPO_CONFIRMADO', this.confirmado)
      this.listarCadastros();
    },
    buscaEmail() {
      console.log(this.filtro);
      this.$store.commit('cadastro_teste/STORE_BUSCA_EMAIL', this.filtro)
      this.listarCadastros()
    }

  },
  filters: {
    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
  },
};
</script>
