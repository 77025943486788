<template>
  <div>
    <lista-cadastro-teste :cadastros="cadastros"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ListaCadastroTeste from './ListaCadastroTeste.vue'
export default {
  components: { 
    ListaCadastroTeste
  },

  mounted() {
    this.$store.dispatch('cadastro_teste/listaCadastros')
  },

  computed: {
    ...mapState({
      cadastros: (state) => state.cadastro_teste.cadastros
    })
  }

}
</script>

<style>

</style>