<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div class="truncate">
              <b-avatar class="mb-1 float-right" variant="light-success" size="45">
                <feather-icon size="21" icon="CheckIcon" />
              </b-avatar>
              <h4 class="mb-25 font-weight-bolder">{{ confirmados }}</h4>
              <b class="text-muted">Confirmados</b>
            </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <div class="truncate">
              <b-avatar class="mb-1 float-right" variant="light-danger" size="45">
                <feather-icon size="21" icon="XIcon" />
              </b-avatar>
              <h4 class="mb-25 font-weight-bolder">{{ nao_confirmados }}</h4>
              <b class="text-muted">Não Confirmados</b>
            </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col>
          <filtros-cadastro-teste />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :items="cadastros.data"
            :fields="fields"
          >
            <template #cell(created_at)="data">
              {{ data.item.created_at | converteData }}
            </template>
            <template #cell(confirmado)="data">
              <b-badge v-if="data.item.confirmado === 1" variant="light-success"
                >Sim</b-badge
              >
              <b-badge v-else variant="light-danger">Não</b-badge>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="cadastros.total"
            :per-page="perPage"
            align="center"
            @input="paginacao"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BAvatar,
  BCol,
  BBadge,
  BButton,
  BTable,
  BPagination,
} from "bootstrap-vue";
import moment from "moment";
import FiltrosCadastroTeste from "./FiltrosCadastroTeste.vue";
import { mapState } from "vuex";
export default {
  components: {
    BCard,
    BAvatar,
    BRow,
    BCol,
    BBadge,
    BButton,
    BTable,
    BPagination,
    FiltrosCadastroTeste,
  },
  props: ["cadastros"],

  data() {
    return {
      fields: [
        { key: "id", label: "Empresa ID" },
        { key: "nome", label: "Nome" },
        { key: "email", label: "Email" },
        { key: "celular", label: "Celular", thStyle: { width: "15%" } },
        { key: "confirmado", label: "Confirmado" },
        { key: "created_at", label: "Data Cadastro" },
      ],
      currentPage: 1,
      perPage: 30
    };
  },
  computed: {
    ...mapState({
      confirmados: (state) => state.cadastro_teste.confirmados,
      nao_confirmados: (state) => state.cadastro_teste.nao_confirmados,
    }),
  },

  methods: {
    paginacao() {
      this.$store.commit('cadastro_teste/STORE_PAGINACAO', this.currentPage)
      this.$store.dispatch('cadastro_teste/listaCadastros')
    }
  },
  filters: {
    converteData(data) {
      if (!data) return;
      return moment(data).format("DD/MM/YYYY");
    },
  },
};
</script>

<style>
</style>